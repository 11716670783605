import * as React from "react";
import { Link } from "gatsby";
require("./footer.scss");

const Footer = () => {
    return (
        <footer id="footer" className="mt-4">
            <Link to="/impressum">Impressum</Link>
            <div>|</div>
            <Link to="/datenschutz">Datenschutz</Link>
        </footer>
    );
};

export default Footer;
