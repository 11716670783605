import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";

require("./header.scss");

const Header = () => {
    function openOverlayNav() {
        document.getElementById("overlay-nav")!.style.height = "100%";
        globalHistory.listen(({ action }) => {
            console.log(action);
            if (action === "PUSH") {
                closeOverlayNav();
            }
        });
    }

    function closeOverlayNav() {
        document.getElementById("overlay-nav")!.style.height = "0%";
    }

    return (
        <header>
            <nav id="header">
                <Link to="/" className="d-flex flex-row  align-items-center" title="Startseite">
                    <StaticImage src="../../static/images/zamgschustert_logo.svg" alt="Firmenlogo Zamgschustert, Holzscheibe mit Fräskopf drüber" className="logo" width={70} style={{ flexGrow: 0 }} />
                    <span style={{ flexGrow: 0 }} id="title" className="ms-5">
                        Zamgschustert
                    </span>
                </Link>
                <div className="d-none d-lg-flex nav-elements">
                    <ul>
                        <li>
                            <Link to="/hochzeiten" activeClassName="aktiv" className="nav-element">
                                Hochzeiten
                            </Link>
                        </li>
                        <li>
                            <Link to="/holzdekoration" activeClassName="aktiv" className="nav-element">
                                Holzdekoration
                            </Link>
                        </li>
                        <li>
                            <Link to="/wohnaccessoires" activeClassName="aktiv" className="nav-element">
                                Wohnaccessoires
                            </Link>
                        </li>
                        <li>
                            <Link to="/kontakt" activeClassName="aktiv" className="nav-element">
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>
                <div style={{ flexGrow: 1, textAlign: "right", gap: "25px", justifyContent: "flex-end" }} className="d-lg-none">
                    <button className="icon-button bi bi-list" onClick={openOverlayNav}></button>
                </div>
            </nav>

            <div id="overlay-nav">
                <div id="button-wrapper">
                    <button className="icon-button bi bi-x-lg" onClick={closeOverlayNav}></button>
                </div>
                <nav className="overlay-content">
                    <ul>
                        <li>
                            <div>
                                <Link to="/" activeClassName="aktiv" className="nav-element">
                                    Start
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/hochzeiten" activeClassName="aktiv" className="nav-element">
                                    Hochzeiten
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/holzdekoration" activeClassName="aktiv" className="nav-element">
                                    Holzdekoration
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/wohnaccessoires" activeClassName="aktiv" className="nav-element">
                                    Wohnaccessoires
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/kontakt" activeClassName="aktiv" className="nav-element">
                                    Kontakt
                                </Link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
