exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-hochzeiten-tsx": () => import("./../../../src/pages/hochzeiten.tsx" /* webpackChunkName: "component---src-pages-hochzeiten-tsx" */),
  "component---src-pages-holzdekoration-tsx": () => import("./../../../src/pages/holzdekoration.tsx" /* webpackChunkName: "component---src-pages-holzdekoration-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-wohnaccessoires-tsx": () => import("./../../../src/pages/wohnaccessoires.tsx" /* webpackChunkName: "component---src-pages-wohnaccessoires-tsx" */)
}

