import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
require("./layout.scss");

const Layout = ({ children }: any) => {
    return (
        <div id="wrapper">
            <Header></Header>
            <main id="main">{children}</main>
            <Footer></Footer>
        </div>
    );
};

export default Layout;
